import React from "react"
import { FormattedHTMLMessage } from "gatsby-plugin-intl"
import coalshopDiagram from "../../images/diagram-coalshop.png";
import coalshopCvejn from "../../images/cvejn-coalshop.png";
import "./RemarkableProducts.scss"


const CoalshopConnector = () => {
    return (
        <section id="remarkableProducts" className="page-section">
            <div className="container">
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="section-title">
                            <h2 className="font-weight-bold">
                                Coalshop konektor pozoruhodným IT produktem roku 2024! Jak vám pomůže?
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="page-text margin-bot font-weight-bold">
                            Jsme nadšeni, že můžeme sdílet úžasnou zprávu! Náš produkt coalshop konektor byl oceněn jako Pozoruhodný IT produkt roku 2024 podle renomovaného časopisu <span className="span-highlight">BusinessIT</span>. Toto ocenění potvrzuje naši snahu a odhodlání poskytovat inovativní a efektivní řešení pro e-commerce a podnikové informační systémy.
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="page-text margin-bot font-weight-bold">
                            Řešení pro Váš podnik
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="stock-container">
                            <div className="stock-image-section">
                                <img src={coalshopDiagram} />
                            </div>
                            <div className="text-section">
                                <p>Máte sklad plný zboží a potřebujete efektivně řídit jeho distribuci přes váš e-shop? Představte si, že byste mohli automaticky doporučovat produkty podle preferencí vašich zákazníků. S <a href="https://coalshop.cz/connector/" target="_blank">coalshop konektorem</a> to je hračka! Spustíme integraci vašeho e-shopu a ERP systému HELIOS během několika dnů, díky naší hluboké odbornosti a zkušenostem. <strong><em>„Ocenění coalshop konektoru je důkazem tvrdé práce a inovací, které náš tým vkládá do vývoje produktů,“</em></strong> těší obchodního manažera Jakuba Cvejna.</p>
                                <p>Jste majitelé menších či středních podniků s vlastním e-shopem a potřebujete efektivně propojit vaše skladové hospodářství a online prodej? Coalshop konektor je řešení přímo pro vás! <strong>Propojujeme libovolný e-shop s ERP systémy, jako jsou <a href="https://coalios.cz/#inuvio" target="_blank">HELIOS Easy a iNuvio</a>, abychom vám usnadnili řízení obchodu z jednoho místa.</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="page-text margin-bot font-weight-bold">
                            Klíčové vlastnosti coalshopu:
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="page-text margin-bot">
                            <ul>
                                <li><strong>Efektivní propojení online a offline obchodování</strong>: Umožňuje snadnou integraci různých ERP systémů a e-shopů.</li>
                                <li><strong>Vysoká bezpečnost</strong>: Nabízí pokročilou vícevrstvou architekturu pro bezpečný přenos dat.</li>
                                <li><strong>Mezinárodní přizpůsobivost</strong>: Podporuje multijazyčné nastavení a více měn, což usnadňuje expanzi na různé trhy.</li>
                                <li><strong>Zvýšená efektivita</strong>: Uživatelé mohou snadno definovat alternativní produkty pro různé trhy, což snižuje provozní náklady.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="page-text margin-bot font-weight-bold">
                            Výhody pro Vaše podnikání:
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="page-text margin-bot">
                            <ul>
                                <li><strong>Rychlé nasazení</strong>: Spouštíme vaše řešení do ostrého provozu během několika dnů.</li>
                                <li><strong>Flexibilita a škálovatelnost</strong>: Bez ohledu na to, zda používáte HELIOS nebo jiný systém, naše řešení lze snadno přizpůsobit a rozšířit podle vašich potřeb.</li>
                                <li><strong>Nižší provozní náklady</strong>: Automatizace a intuitivní ovládání znamenají méně chyb a větší efektivitu.</li>
                                <li><strong>Široká propojenost</strong>: Propojuje nejen e-shopy, ale také aplikace pro obchodníky.</li>
                                <li><strong>Vysoká bezpečnost přenosů</strong>: A to díky vícevrstvé architektuře.</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="stock-container">
                            <div className="cvejn-image-section">
                                <img src={coalshopCvejn} />
                            </div>
                            <div className="text-section">
                                <p>Výčet výhod coalshop konektoru zdaleka nekončí! Je navíc možné definovat alternativní zboží pro B2B zákazníky na slovenském e-shopu, zatímco český B2C zákazník bude mít zobrazeny alternativní produkty zcela jiné. <strong><em>„Naším cílem je neustále zlepšovat a přizpůsobovat naše řešení tak, aby co nejlépe vyhovovala potřebám našich zákazníků,“</em></strong> upozorňuje obchodní manažer naší společnosti coalios, Jakub Cvejn.</p>
                                <p>Pro více informací o našem produktu a jeho výhodách navštivte naše webové stránky <a href="https://coalshop.cz/connector/" target="_blank">coalshop.cz</a>.</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section >
    )
}

export default CoalshopConnector;